// src/components/Header.js
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const navItems = ['Home', 'Menu', 'Create Your Order', 'About Us', 'Contact'];

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {/* Business Name in Drawer */}
      <Typography
        variant="h4"
        sx={{
          my: 2,
          fontFamily: "'Dancing Script', cursive",
          fontWeight: 'bold',
        }}
      >
        The Sweet House
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem
            button
            key={item}
            component="a"
            href={`#${item.toLowerCase().replace(/\s/g, '')}`}
          >
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" color="primary" elevation={0}>
        <Toolbar>
          {/* Business Name in AppBar */}
          <Typography
            variant="h4"
            sx={{
              flexGrow: 1,
              fontFamily: "'Dancing Script', cursive",
              fontWeight: 'bold',
              color: '#fff',
              textDecoration: 'none',
            }}
            component="a"
            href="#"
          >
            The Sweet House
          </Typography>

          {isMobile ? (
            // Mobile View: Show Hamburger Menu
            <IconButton
              color="inherit"
              aria-label="open navigation menu"
              edge="end"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            // Desktop View: Show Navigation Links
            <Box sx={{ display: 'flex', gap: 2 }}>
              {navItems.map((item) => (
                <Typography
                  key={item}
                  variant="button"
                  component="a"
                  href={`#${item.toLowerCase().replace(/\s/g, '')}`}
                  sx={{
                    color: '#fff',
                    textDecoration: 'none',
                    fontSize: '1.1rem',
                    '&:hover': {
                      borderBottom: '2px solid #fff',
                    },
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Navigation */}
      <Drawer
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Header;
