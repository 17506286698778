// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import Header from './components/Header';
import HeroSection from './components/HeroSection';
import Menu from './components/Menu';
import CreateOrder from './components/CreateOrder';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => (
  <ThemeProvider theme={theme}>
    <Header />
    <HeroSection />
    <Menu />
    <CreateOrder />
    <Testimonials />
    <Contact />
    <Footer />
  </ThemeProvider>
);

export default App;
