// src/components/Testimonials.js
import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
} from '@mui/material';

const testimonials = [
  {
    id: 1,
    name: 'Emily Johnson',
    feedback:
      'The cakes from The Sweet House are absolutely divine! The flavors are rich, and the presentation is impeccable.',
    avatarUrl: '/assets/avatar1.jpg',
  },
  {
    id: 2,
    name: 'Michael Smith',
    feedback:
      'I ordered a custom cake for my wife’s birthday, and it exceeded all expectations. Highly recommended!',
    avatarUrl: '/assets/avatar2.jpg',
  },
  {
    id: 3,
    name: 'Sophia Williams',
    feedback:
      'The sweets are not only delicious but also beautifully crafted. I’m a regular customer now!',
    avatarUrl: '/assets/avatar3.jpg',
  },
  // Add more testimonials as needed
];

const Testimonials = () => (
  <Container sx={{ padding: '4rem 0' }}>
    <Typography variant="h4" align="center" gutterBottom>
      Testimonials
    </Typography>
    <Grid container spacing={4}>
      {testimonials.map((testimonial) => (
        <Grid item xs={12} md={4} key={testimonial.id}>
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: '24px', }}>
            <CardContent
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Avatar
                src={testimonial.avatarUrl}
                alt={testimonial.name}
                sx={{ width: 80, height: 80, mb: 2 }}
              />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {testimonial.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                "{testimonial.feedback}"
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Container>
);

export default Testimonials;
