// src/components/HeroSection.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import logo from '../assets/logo.png'; // Import your logo
import backgroundImage from '../assets/diversos.jpg'; // Background image

const HeroSection = () => (
  <Box
    sx={{
      position: 'relative',
      height: { xs: '60vh', md: '70vh' },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      textAlign: 'center',
      overflow: 'hidden',
    }}
  >
    {/* Background Image */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: -2,
      }}
    />

    {/* Overlay */}
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust opacity as needed
        zIndex: -1,
      }}
    />

    {/* Content */}
    <Box sx={{ padding: 2 }}>
      {/* Logo */}
      <Box
        component="img"
        src={logo}
        alt="The Sweet House Logo"
        sx={{
          height: { xs: '100px', sm: '140px', md: '180px' },
          marginBottom: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Adds a white background to the logo
          borderRadius: '8px', // Optional: Rounds the corners of the logo background
          padding: 1, // Optional: Adds padding around the logo
        }}
      />

      <Typography
        variant="h2"
        gutterBottom
        sx={{ fontSize: { xs: '2rem', sm: '3rem', md: '4rem' } }}
      >
        Welcome to The Sweet House
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem', md: '2rem' } }}
      >
        Indulge in our exquisite handmade confections
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        sx={{ mt: 2 }}
        href="#menu"
      >
        Explore Our Menu
      </Button>
    </Box>
  </Box>
);

export default HeroSection;
