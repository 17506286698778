// src/components/Menu.js
import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
} from '@mui/material';
import Rosinha from '../assets/rosinha.jpg';
import Nozes from '../assets/nozes.jpg';
import Pretobranco from '../assets/pretobranco.jpg';
import Rosinhabranco from '../assets/rosinhabranco.jpg';
import Crocante from '../assets/crocante.jpg';
import Brigadeiro from '../assets/brigadeiro.jpg';
import Nutela from '../assets/nutela.jpg';
import Beijinho from '../assets/beijinho.jpg';
import Diversos from '../assets/diversos.jpg';

const Menu = () => {
  // Updated menu items with elegant names and descriptions
  const items = [
    {
      id: 1,
      name: 'Coconut Velvet Delight',
      description:
        'An exquisite truffle infused with velvety coconut cream and a hint of strawberry essence.',
      price: '£12',
      imageUrl: Rosinha,
    },
    {
      id: 2,
      name: 'Classic Walnut Indulgence',
      description:
        'A sumptuous cake enriched with crunchy walnuts and a touch of cinnamon.',
      price: '£12',
      imageUrl: Nozes,
    },
    {
      id: 3,
      name: 'Noir et Blanc Gâteau',
      description:
        'A sophisticated fusion of dark chocolate and vanilla in a layered masterpiece.',
      price: '£12',
      imageUrl: Pretobranco,
    },
    {
      id: 4,
      name: 'Strawberry Chantilly Torte',
      description:
        'Delicate layers of sponge with fresh strawberries and whipped Chantilly cream.',
      price: '£12',
      imageUrl: Rosinhabranco,
    },
    {
      id: 5,
      name: 'Caramel Praline Temptation',
      description:
        'An indulgent cake adorned with crunchy caramel pralines and silky frosting.',
      price: '£12',
      imageUrl: Crocante,
    },
    {
      id: 6,
      name: 'Beijinho Bliss',
      description:
        'Traditional Brazilian coconut confection rolled in fine sugar crystals.',
      price: '£12',
      imageUrl: Beijinho,
    },
    {
      id: 7,
      name: 'Brigadeiro Elegance',
      description:
        'Classic Brazilian chocolate fudge truffle enrobed in chocolate sprinkles.',
      price: '£12',
      imageUrl: Brigadeiro,
    },
    {
      id: 8,
      name: 'Nutella Dream Cake',
      description:
        'A decadent creation with layers of moist cake and rich Nutella cream.',
      price: '£12',
      imageUrl: Nutela,
    },
    {
      id: 9,
      name: 'Confectioner’s Selection',
      description:
        'A curated assortment of our finest sweets to delight every palate.',
      price: '£12',
      imageUrl: Diversos,
    },
    // Add more items as needed
  ];

  return (
    <Container
      maxWidth={false}
      sx={{ maxWidth: '1200px', padding: '4rem 0', margin: '0 auto' }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Our Menu
      </Typography>
      <Grid container spacing={4}>
        {items.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id} sx={{marginTop: '24px',}}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                margin: 'auto',
              }}
            >
              {item.imageUrl && (
                <CardMedia
                  component="img"
                  alt={item.name}
                  image={item.imageUrl}
                  sx={{ height: '200px', objectFit: 'cover' }}
                />
              )}
              <CardContent
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {item.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ marginBottom: '1rem', flexGrow: 1 }}
                >
                  {item.description}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 'medium', textAlign: 'right' }}
                >
                  {item.price}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Menu;
