// src/components/CreateOrder.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Alert,
  Box,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const CreateOrder = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_ORDER;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    emailjs.send(serviceID, templateID, data, publicKey).then(
      (result) => {
        console.log('Order email successfully sent!', result.text);
        reset();
        setIsSubmitting(false);
      },
      (error) => {
        console.error('There was an error sending the order email:', error.text);
        setErrorMessage(
          'An error occurred while sending your order. Please try again later.'
        );
        setIsSubmitting(false);
      }
    );
  };

  return (
    <Container maxWidth="md" sx={{ padding: '4rem 0' }} id="createyourorder">
      <Typography variant="h4" align="center" gutterBottom>
        Create Your Order
      </Typography>
      {isSubmitSuccessful && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Your order request has been sent successfully! We will contact you soon.
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Contact Information */}
        <Typography variant="h6" gutterBottom>
          Your Contact Information
        </Typography>
        <Grid container spacing={2}>
          {/* Full Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Full Name"
              variant="outlined"
              {...register('name', { required: 'Name is required' })}
              error={Boolean(errors.name)}
              helperText={errors.name?.message}
              required
              aria-required="true"
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              {...register('phone', { required: 'Phone number is required' })}
              error={Boolean(errors.phone)}
              helperText={errors.phone?.message}
              required
              aria-required="true"
            />
          </Grid>

          {/* Email Address */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email Address"
              type="email"
              variant="outlined"
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Enter a valid email address',
                },
              })}
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              required
              aria-required="true"
            />
          </Grid>
        </Grid>

        {/* Order Details */}
        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
          Order Details
        </Typography>
        <TextField
          fullWidth
          label="Items and Preferences"
          variant="outlined"
          multiline
          rows={6}
          placeholder="Please describe the items you'd like to order, including any preferences or special requests."
          {...register('orderDetails', { required: 'Order details are required' })}
          error={Boolean(errors.orderDetails)}
          helperText={errors.orderDetails?.message}
          required
          aria-required="true"
        />

        {/* Submit Button */}
        <Box sx={{ textAlign: 'center', marginTop: '2rem' }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isSubmitting}
            aria-label="Submit Order Request"
          >
            {isSubmitting ? 'Sending...' : 'Submit Order Request'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default CreateOrder;
