// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';

const Footer = () => (
  <Box sx={{ backgroundColor: 'primary.main', color: '#FFF', padding: 4 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">The Sweet House</Typography>
        <Typography variant="body2">
          Exquisite handmade confections crafted with love.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">Quick Links</Typography>
        <Link href="#" color="inherit" display="block">Home</Link>
        <Link href="#" color="inherit" display="block">Menu</Link>
        <Link href="#" color="inherit" display="block">About Us</Link>
        <Link href="#" color="inherit" display="block">Contact</Link>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h6">Follow Us</Typography>
        <Link href="https://www.instagram.com/your_instagram_username/" color="inherit" display="block">Instagram</Link>
        {/* Add other social media links */}
      </Grid>
    </Grid>
    <Typography variant="body2" align="center" sx={{ marginTop: 2 }}>
      © {new Date().getFullYear()} The Sweet House. All rights reserved.
    </Typography>
  </Box>
);

export default Footer;
