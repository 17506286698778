// src/components/Contact.js
import React, { useState } from 'react';
import {
  TextField,
  Button,
  Typography,
  Container,
  Alert,
  Box,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm();

  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = (data) => {
    setIsSubmitting(true);

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_CONTACT;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    emailjs.send(serviceID, templateID, data, publicKey).then(
      (result) => {
        console.log('Email successfully sent!', result.text);
        reset();
        setIsSubmitting(false);
      },
      (error) => {
        console.error('There was an error sending the email:', error.text);
        setErrorMessage(
          'An error occurred while sending your message. Please try again later.'
        );
        setIsSubmitting(false);
      }
    );
  };

  return (
    <Container maxWidth="sm" sx={{ padding: '4rem 0' }} id="contact">
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      {isSubmitSuccessful && (
        <Alert severity="success" sx={{ mb: 2 }}>
          Your message has been sent successfully!
        </Alert>
      )}
      {errorMessage && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {errorMessage}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        {/* Name Field */}
        <TextField
          fullWidth
          label="Your Name"
          variant="outlined"
          margin="normal"
          {...register('name', { required: 'Name is required' })}
          error={Boolean(errors.name)}
          helperText={errors.name?.message}
          required
          aria-required="true"
        />

        {/* Email Field */}
        <TextField
          fullWidth
          label="Your Email"
          type="email"
          variant="outlined"
          margin="normal"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Enter a valid email address',
            },
          })}
          error={Boolean(errors.email)}
          helperText={errors.email?.message}
          required
          aria-required="true"
        />

        {/* Message Field */}
        <TextField
          fullWidth
          label="Your Message"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          {...register('message', { required: 'Message is required' })}
          error={Boolean(errors.message)}
          helperText={errors.message?.message}
          required
          aria-required="true"
        />

        {/* Submit Button */}
        <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
            disabled={isSubmitting}
            aria-label="Send Message"
          >
            {isSubmitting ? 'Sending...' : 'Send Message'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Contact;
